
const saveOpinion = (state = '', action) => {
    switch (action.type) {
        case 'SAVE_OPINION':
            return action.opinion
        default:
            return state
    }
}

export default saveOpinion