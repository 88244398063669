import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
function ShareDialog(props) {
    const { open, copied, onCopied, onClose } = props;
    // const handleClose = () => {
    //     onClose(selectedValue);
    // };

    // const handleListItemClick = value => {
    //     onClose(value);
    // };

    const onTextAreaTouch = (e) => {
        e.target.select();
        document.execCommand('copy');
        e.target.focus()
        onCopied()
        // this.setOpen(true);
    }

    return (
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">공유하기</DialogTitle>
            <DialogContent>
                <div className="popup">
                    <p>공유하기를 원하시면 아래 주소를 터치해주세요.</p>
                    <textarea
                        className="text-center"
                        value={`${decodeURI(window.location.href)}`}
                        readOnly
                        onClick={onTextAreaTouch}
                    />
                    {
                        copied ? 
                        <p>
                            주소가 복사되었습니다!
                        </p>
                        : null
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>닫기</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShareDialog