import { connect } from 'react-redux';
import HomeScreen from './HomeScreen';

const mapStateToProps = (state) => {
    return {
        isMobile: "MOBILE" === state.setMediaFilter
  }
}

const FilterHomeScreen = connect(
  mapStateToProps
)(HomeScreen)

export default FilterHomeScreen