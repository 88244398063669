import React from 'react';

import logo from '../../assets/img/logo1_tight.png'
import Header from '../../components/Header';
import juho from '../../assets/img/juho.jpg'
function AboutScreen() {
    return (
        <div className="wrapper">
            <Header />
            <div className="container">
                <div className="title welcome-box text-center text-light text-sm-spacing">
                    <img src={logo} alt="별별생각" height="100%"></img>
                    <div>서로 다른 생각이 빛나는 곳</div>
                    <br />
                    <div></div>
                </div>
                <div className="title blurb">
                    ‘별별생각’은 다양한 사람들의 다양한 의견을 들으면서 보다 넓은 세상을 볼 수 있도록 도와주는 시스템입니다. 여러 사회 이슈에 대한 내 의견을 공유하고, 다양한 사람들의 다양한 의견을 보면서 내가 생각하던 것이 전부가 아니라는 것, 세상에는 정말 ‘별별생각’이 다 있다는 것을 알아 가세요.
                    {/* '별별생각’은 보다 더 다양한 사람들에게서 보다 더 다양한 의견을 들을 수 있게, 
                    우리가 보는 세상을 더 넓게 만들기 위한 시스템입니다. 여러 이슈에 대해 의견을 수집하고, 
                    다양한 사람들의 의견을 보면서 내가 생각하던 것이 전부가 아니라는 것, 
                    세상에는 정말 ‘별별생각’이 다 있다는 것을 알아 가세요. */}
                </div>
                <div className="text-center">
                    <a className="underline" href="https://cs.kaist.ac.kr">KAIST 전산학부</a> <a className="underline" href="https://kixlab.org" target="_blank">김주호 교수 연구 팀</a>이 <a className="underline" href="http://www.sdf.or.kr/2019/?lang=kr" target="_blank">SBS SDF 팀</a>과 공동으로 기획한 실험입니다.
                
                    {/* SDF 2019를 위한 <a className="underline" href="http://www.sdf.or.kr/2019/?lang=kr" target="_blank">SBS SDF 팀</a>과 <a className="underline" href="https://kixlab.org" target="_blank">KAIST 인터랙션 연구실</a>의 협력 연구입니다. */}
                </div>
                <div className="text-center creators" id="creators">
                    <div className="creators-text">만든 사람들</div>
                    <div className="creator">
                        <div className="person">
                            {/* <div className="img">
                                <img src={juho} alt="Juho Kim"></img>
                            </div> */}
                            고은영
                        </div>
                        <div className="person">
                            {/* <div className="img">
                                <img src={juho} alt="Juho Kim"></img>
                            </div> */}
                            김주호
                        </div>
                        <div className="person">
                            김해수
                        </div>
                        <div className="person">
                            김현우
                        </div>
                        <div className="person">
                            서한교
                        </div>
                        <div className="person">
                            조경제
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    불편하시거나 궁금하신 점이 있다면 <a className="underline" href="mailto:byulbyul@kixlab.org">byulbyul@kixlab.org</a>로 연락주세요.
                </div>
            </div>
        </div>
    )
}

export default AboutScreen;