import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Button from '../../components/Button';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    const { setMediaFilter, saveAPIToken } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        token: saveAPIToken
    }
  }

const textDesktop = `
1. 연구의 배경 및 목적
많은 사람들은 인터넷이 다양한 사람들이 자유롭게 서로의 의견을 이야기할 수 있는 공론장이 되어줄 것이라고 믿었습니다. 다른 의견을 경청하고 인정하는 경우도 있지만, 같은 의견을 가진 사람들끼리 뭉쳐 다른 의견을 배척하고 비난하는 경우도 많습니다. 의견의 양극화가 곳곳에서 나타나고 있는 온라인 상에서, “다름”의 의미는 무엇이고, 내 의견은 어떤 요소에 의해 영향을 받을까요?
어떤 사회 문제에 대해 나와 다른 의견을 가진 사람이라 할지라도 나와 공통점이 있다면, 그 사람의 의견에 한 번쯤 귀를 기울일 수 있지 않을까요? 또는, 나와 비슷한 상황에 있는 사람이 어떤 사회 문제에 대해 정반대의 의견을 가지고 있다면, 왜 그렇게 생각하는지 진지하게 알아보게 되지 않을까요? 본 연구에서는 참여자 여러분께 사회 문제에 대한 의견과 함께 여러분의 나이와 성별, 정치 성향 등 참여자 여러분의 특징을 모으고, 이 정보를 이용하여 의견의 분포와 유사도를 시각화하는 온라인 시스템 “별별생각”을 개발하고자 합니다.
2. 예상 참여시간 및 참여 중단
본 시스템 사용에 시간적 제한은 없습니다.
3. 본 연구에 참여하게 됨으로써 받게 되는 검사 및 절차
본 연구는 만 18세 이상 만 64세 이하 대한민국 국민을 대상으로 합니다. “별별생각”은 누구나 아무 제약 없이 사용할 수 있지만, 연구 대상자에 해당하지 않는 참가자의 데이터는 연구 목적으로 사용되지 않습니다. 시스템 사용 시 수집된 웹 기록은 연구용 서버에 저장되며 이외에 실험을 전후로 이행되는 특별한 검사 및 절차는 없습니다.
4. 이 연구에 참여함으로써 기대되는 이익
해당 시스템이 다루는 사회적, 정치적 문제에 대한 새로운 접근을 경험하실 수 있습니다. 또한, 연구를 통해 얻은 정보는 추후 관련 연구에 도움이 됩니다.
5. 신분의 비밀보장 및 개인정보 보호(자료의 열람 권한 설정 및 보관, 관리, 폐기, 임상시험 결과 발표 시 피험자의 신원 보호)
개인정보 보호를 위해 모든 데이터는 익명으로 처리됩니다. 특히, 카카오 계정 연동으로 받아오는 정보는 이메일을 제외한 어떠한 정보도 저장하지 않습니다. 
추후 연구결과를 발표하게 될 시, 결과 내용에 연구 대상자의 신원이 드러나는 식별정보는 포함되지 않을 것입니다. 수집된 개인정보는 외부에서 접근이 제한된 서버에 저장이 되며, 참여연구원에 의해서만 처리 및 가공되어 연구결과를 도출하는 용도로만 이용될 것입니다. 수집된 개인정보는 연구 기간 종료 후 전량 파기 조치하고, 만일 데이터 수집 기간 동안 참가자로부터 수집 중단 및 데이터 이용 중단 요구가 있을 경우 해당 참가자의 데이터는 모두 파기할 것입니다.
6. 연구와 관련된 손상이 발생하였을 경우 피험자에게 주어질 보상이나 치료방법
본 연구는 연구 참가자에게 어떤 조작을 가하거나 육체적인 활동을 요구하지 않습니다. 만약 이 연구에 의한 손상이 발생했다고 판단될 경우 그에 상응하는 보상을 할 것입니다.
7. 연구 참가 거부 및 중단
본 실험에 참여 여부를 결정하는 것은 참가자에게 달려있습니다. 참가자는 언제든지 연구에 참여하지 않기로 결정할 수 있고, 또한 중도에 그만둘 수 있습니다. 참가자가 본 연구에 참여하지 않아도 아무런 불이익을 받지 않습니다. 시스템 사용 중 불편을 느끼고 연구 참가를 중단하고 싶다면 언제든지 중지할 수 있습니다. 그리고 수집된 데이터의 이용 중단을 요구할 경우 연구진은 수집 데이터 일체를 파기할 것입니다.
8. 본 연구를 위해 피험자가 준수해야 하는 사항
본 연구를 오염시키기 위한 악의적인 목적으로 시스템 사용에 임하지 말아야 합니다. 연구원이 참가자가 악의적인 목적이 있다고 판단한다면, 관련 데이터를 파기할 수 있습니다.
9. 연구에 대한 추가적인 정보를 얻고자 하거나 연구와 관련이 있는 손상이 발생한 경우에 접촉해야 하는 사람과 연락처
본 연구의 참여와 관련하여 문의 및 불만 사항이 있으신 경우에는 언제든지 연구진에게 연락하여 주시기 바랍니다.

연구 책임자:
김주호, 카이스트 전산학부 조교수, juhokim@kaist.ac.kr 
연락 담당자:
김현우, 카이스트 전산학부, khw0726@kaist.ac.kr
본인은 이 동의서를 읽고 이해하였으며 본인은 자발적으로 본 연구에 참여함을 확인합니다.

 또한, 본인은
   1. 본인과 연구자 및 KAIST 사이에 본인의 연구 참여 결정에 영향을 줄 수 있는 어떠한 관계도 없고,
   2. 인지 기능에 문제가 있지 아니함을 확인합니다
   `

const textMobile = textDesktop //'귀하는 서비스 내에서 적용되는 모든 정책을 준수해야 합니다.\n플랫폼 서비스의 오용을 삼가시기 바랍니다. 예를 들어 서비스를 방해하거나 플랫폼이 제공하는 인터페이스 및 안내사항 이외의 다른 방법을 사용하여 액세스를 시도하지 않아야 합니다. 귀하는 관련 수출 및 재수출 통제 법규 및 규정 등 오직 법률상 허용되는 범위에서만 플랫폼 서비스를 이용할 수 있습니다. 귀하가 플랫폼 약관이나 정책을 준수하지 않거나 플랫폼이 부정행위 혐의를 조사하고 있는 경우, 플랫폼 서비스 제공이 일시 중지 또는 중단될 수 있습니다.'

class ConsentScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            backgroundImage: ''
        }
        this.consentAgreeHandler = this.consentAgreeHandler.bind(this);
    }
    componentDidMount() {
        this.setState({backgroundImage: document.body.style.backgroundImage});
        document.body.style.backgroundImage = 'none';
        document.body.style.color = '#000000';
    }

    componentWillUnmount() {
        const { backgroundImage } = this.state;
        document.body.style.backgroundImage= backgroundImage;
        document.body.style.color= '#ffffff';
    }

    consentAgreeHandler() {
        const { token } = this.props;
        if (token) {
            fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                method: 'put',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    consentAgreed: true
                })
            })
        }
        this.props.history.push('/AnswerIssue')
    }

    render() {
        const { isMobile } = this.props;
        return (
            <div className="wrapper">
                <Header/>
                <div className="container">
                    {/* <div className="title">
                        '별별생각’은 보다 더 다양한 사람들에게서 보다 더 다양한 의견을 들을 수 있게, 
                        우리가 보는 세상을 더 넓게 만들기 위한 시스템입니다. 여러 이슈에 대해 의견을 수집하고, 
                        다양한 사람들의 의견을 보면서 내가 생각하던 것이 전부가 아니라는 것, 
                        세상에는 정말 ‘별별생각’이 다 있다는 것을 알아 가세요.
                    </div> */}
                    <div className="title text-bold">
                        ‘별별생각‘에 참여하신 여러분의 소중한 데이터는 연구 목적으로 활용됩니다.
                        <br></br>
                         아래 설명을 읽고 동의하시면 ‘다음’을 눌러 주세요.
                    </div>
                    <div className="description">
                        <div className="text-center">
                            인간 대상 연구 피험자 동의서
                        </div>
                        {isMobile ?
                        textMobile.split('\n').map((item, i) => {
                            return <div key={i}><p>{item}</p><br/></div>
                        })
                        :textDesktop.split('\n').map((item, i) => {
                            return <div key={i}><p>{item}</p><br/></div>
                        })
                        }
                    </div>
                </div>
                <div className="next">
                    <Button type="button-gray button text-bold" content="다음" onClick={this.consentAgreeHandler}/>
                </div>
            </div>
        )
    }
}

ConsentScreen.propTypes = {
    isMobile: PropTypes.bool.isRequired
}

export default connect(mapStateToProps)(ConsentScreen);