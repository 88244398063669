
const saveAPIToken = (state = '', action) => {
    switch (action.type) {
        case 'SAVE_API_TOKEN':
            return action.token
        default:
            return state
    }
}

export default saveAPIToken