import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Button from '../../components/Button';
import Response from '../../components/Response';
import { saveOpinion, selectOption } from '../../actions';

import { connect } from 'react-redux';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption, saveAPIToken, saveIssueTitle} = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
        token: saveAPIToken,
        issueTitle: saveIssueTitle
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        opinion: (opinion) => dispatch(saveOpinion(opinion)),
        select: (option) => dispatch(selectOption(option))
    }
}

class SelectScreen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            identityQuestions: [],
            responseType: 'likertscale',
            identityOptions: [],
            info: {marks: ['매우 반대', '반대', '중립', '찬성', '매우 찬성']},
            question: 0,
            stance: -1,
            currentQuestionType: 'stance',
            questionOrder: ['stance', 'freeform', 'identityOptions', 'identityQuestions'],
            identityOptionIndex: 0,
            identityQuestionIndex: 0,
            argument: '',
            identities: [],
            author: {age: '', gender: '', occupation: '', politicalStance: '',},
        }
    }

    componentDidMount() {
        const { issue, token, issueTitle } = this.props;
        this.setState({
            title: issueTitle
        })
        fetch(`${process.env.REACT_APP_URL}/dev/issues/${issue}`, {
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            // description, identityQuestions, title, _id
            .then((res) => res.json())
            .then((response) => {
                this.setState({
                    title: response.issue.title,
                    description: response.issue.description,
                    identityQuestions: response.issue.identityQuestions,
                    identityOptions: response.identityOptions
                })
            })
            .catch((err) => console.log(err));
        this.onClickHandler = this.onClickHandler.bind(this);
        this.toComplete = this.toComplete.bind(this);
    }

    toComplete() {
        const { issue, token, opinion } = this.props;
        const { stance, argument, identities, author } = this.state;
        const users = {
            age: author.age,
            gender: author.gender,
            occupation: author.occupation,
            politicalStance: author.politicalStance
        }
        const opinions = {
            issue: issue, // 서버에서 돌려준 issue의 object ID,
            stance: stance, // 정책에 대한 5-point Likert scale (1: 매우 반대, 5: 매우 찬성)
            argument: argument, // "왜 그렇게 생각하셨나요?"에 대한 대답.
            identities: identities
        };
        localStorage.setItem('users', JSON.stringify(users));
        localStorage.setItem('opinions', JSON.stringify(opinions));
        if (token) {
            if(author.age || author.gender || author.occupation || author.politicalStance) {
                fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                    method: 'put',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        age: author.age,
                        gender: author.gender,
                        occupation: author.occupation,
                        politicalStance: author.politicalStance
                    })
                })
            }
            fetch(`${process.env.REACT_APP_URL}/dev/opinions`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    issue: issue, // 서버에서 돌려준 issue의 object ID,
                    stance: stance, // 정책에 대한 5-point Likert scale (1: 매우 반대, 5: 매우 찬성)
                    argument: argument, // "왜 그렇게 생각하셨나요?"에 대한 대답.
                    identities: identities
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    opinion(response._id);
                })  
        }
        
        this.props.history.push('/complete');
    }
    
    onClickHandler() {
        const { option, select } = this.props;
        const { currentQuestionType, identityOptions, identityOptionIndex, identityQuestions, identityQuestionIndex, author, identities } = this.state;
        const _options = ['age', 'gender', 'occupation', 'politicalStance']
        if ( currentQuestionType === 'stance' ) {
            const stance = parseInt(option);
            this.setState({
                stance: stance,
                currentQuestionType: 'freeform'
            }, () => select(''))
        }
        else if ( currentQuestionType === 'freeform' ) {
            const argument = option;
            console.log(argument);
            this.setState({
                argument: argument
            }, () => {
                select('');
                if ( identityOptions.length > 0 ) { this.setState({currentQuestionType: 'identityOptions'})}
                else if ( identityQuestions.length > 0) { this.setState({currentQuestionType: 'identityQuestions'})}
                else { this.toComplete() }
            })
        }
        else if ( currentQuestionType === 'identityOptions' ) {
            author[_options[identityOptionIndex]] = option;
            this.setState({
                author: author
            }, () => select(''))
            if ( identityOptionIndex + 1 < identityOptions.length ) {
                this.setState({
                    identityOptionIndex:  identityOptionIndex+1
                })
            }
            else if ( identityQuestions.length > 0) { this.setState({currentQuestionType: 'identityQuestions'})}
            else { this.toComplete() }
        }
        else if ( currentQuestionType === 'identityQuestions' ) {
            const _id = identityQuestions[identityQuestionIndex]._id;
            identities.push({identityQuestion: _id, value: option});
            this.setState({
                identities: identities
            }, () => select(''))
            if ( identityQuestionIndex + 1 < identityQuestions.length ) {
                this.setState({
                    identityQuestionIndex:  identityQuestionIndex+1
                })
            }
            else { this.toComplete() }
        }
        else if ( currentQuestionType === 'identityQuestions' && identityQuestionIndex === identityQuestions.length ) {
            // send to the server
            this.toComplete()
        }
    }

    render() {
        // const options = ['20대', '30대', '40대', '50대', '60대']
        const { title, identityQuestions, identityQuestionIndex,
            info, currentQuestionType, identityOptions, identityOptionIndex } = this.state;
        const { option } = this.props;
        return (
            <div className="wrapper">
                <Header />
                {
                    currentQuestionType === 'stance' ?
                    <div className="container">
                        <div className="title text-light text-center title-issue">
                            "{title}"에 대해 어떻게 생각하시나요?
                        </div>
                        <Response responseType={"likertscale"} options={info} />
                    </div>
                    :
                    currentQuestionType === 'freeform' ?
                    <div className="container">
                        <div className="title text-light text-center title-issue">
                            그렇게 생각하신 이유는 무엇인가요?
                        </div>
                        <Response responseType={"freeform"} />
                    </div>
                    :
                    currentQuestionType === 'identityOptions' ?
                    <div className="container">
                        <div className="title text-light text-center title-issue">
                            {identityOptions[identityOptionIndex].question}
                        </div>
                    <Response responseType={identityOptions[identityOptionIndex].type} options={identityOptions[identityOptionIndex].options} />
                    </div>
                    :
                    currentQuestionType === 'identityQuestions' ?
                    <div className="container">
                        <div className="title text-light text-center title-issue">
                        {identityQuestions[identityQuestionIndex].question}
                        </div>
                    <Response responseType={identityQuestions[identityQuestionIndex].type} options={identityQuestions[identityQuestionIndex].options} />
                    </div>
                    :
                    null
                }
                {/* <Response responseType={responseType} options={info}/> */}
                <div className="next">
                    <Button type={`select text-bold ${((currentQuestionType === 'freeform' && option.length < 10) || (currentQuestionType !== 'freeform' && option.length === 0)) ? 'disabled' : 'button-gradient'}`} content="다음" onClick={(currentQuestionType === 'freeform' && option.length < 10) || (currentQuestionType !== 'freeform' && option.length === 0) ? null : this.onClickHandler}/>
                </div>
            </div>
        )
    }
}

SelectScreen.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    issue: PropTypes.string.isRequired,
    option: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectScreen);