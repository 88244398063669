import React from 'react';
import logo from '../../assets/img/logo1_tight.png'
import ShareIcon from '@material-ui/icons/Share'
import DesktopDialog from '../DesktopDialog'
function Header(props) {
    const location = document.location.href

    const determineHref = () => {
        // if (location.includes('about') || location.includes('consent')
        //     || (location.includes('other')) || location.includes('AnswerIssue')) {
        //     return props.history.push('/')
        // } else if (location.includes('result') || location.includes('select')
        //     || (location.includes('other'))) {
        //     return props.history.push('/')
        // }
        return '/'
    }
    const isMobile = props.isMobile
    const enableMobileView = props.enableMobileView
    const onMobileViewToggleClick = () => {
        if (isMobile) {
            document.querySelector('meta[name="viewport"]').setAttribute("content", '');
        } else {
            document.querySelector('meta[name="viewport"]').setAttribute("content", 'width=device-width, maximum-scale=1');
        }
        console.log(isMobile, enableMobileView)
    }

    const UA = window.navigator.userAgent.toLowerCase()
    const isIos = /ipod|ipad|iphone/.test(UA);
    return(
        <div className={`header ${location.includes('result') ? '': 'text-center'}`}>
            <a href={determineHref()}>
                <img className={`${location.includes('result') ? 'left': 'text-center'}`} src={logo} alt="별별생각" height="100%"></img>
            </a>
            {
                location.includes('result') && isMobile ? 
                (
                    <div className="right">
                        <ShareIcon onClick={props.onShareClick}></ShareIcon>
                    </div>
                )
                :
                null
            }
            {
                (enableMobileView) && (
                    isIos ? 
                    <DesktopDialog></DesktopDialog> :
                    <div className="right" onClick={onMobileViewToggleClick}>
                        <span>{isMobile ? '의견 별별보기' : '모바일 버전으로 돌아가기'}</span>
                    </div>
                )

            }
        </div>
    )
}

export default Header