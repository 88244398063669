import React from 'react';
import Slider from '@material-ui/core/Slider';

import { connect } from 'react-redux';
import { selectOption } from '../../actions';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        select: (option) => dispatch(selectOption(option))
    }
}

class LikertScale extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: ''
        }
    }

    componentDidMount() {
        const { select, defaultValue } = this.props;
        select(defaultValue.toString());
        console.log(defaultValue)
    }

    handleSliderChange = (event, newValue) => {
        const { select } = this.props;
        this.setState({
            currentValue: newValue
        })
        select(newValue.toString());
    };

    render() {
        const { marks, defaultValue } = this.props;
        return (
            <div className="likertscale-wrapper">
                <Slider
                    className='likertscale'
                    defaultValue={defaultValue}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="auto"
                    onChange={this.handleSliderChange}
                    min={1}
                    max={5}
                    marks={marks.map((value,i) => {
                            return {value: i+1, label: value}
                        })
                    }
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LikertScale);