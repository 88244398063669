import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

function ShareDialog(props) {

    // const { open, onClose } = props

    const [open, setOpen] = React.useState(false)
    // const { open, onOpen, onClose } = props;

    const onOpen = () => {
        setOpen(true)
    }

    const onClose = () => {
        setOpen(false)
    }


    const onTextAreaTouch = (e) => {
      e.target.select();
      document.execCommand('copy');
      e.target.focus()
      // this.setOpen(true);
  }

    return (
      <div className="right">
        <div onClick={onOpen}>
            <span>의견 별별보기</span>
        </div>
        <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">공유하기</DialogTitle>
            <DialogContent>
                <div className="popup">
                    <p>PC에서 아래 주소를 들어가시면 의견의 별을 볼 수 있습니다.</p>
                    <textarea
                        className="text-center"
                        value={`${decodeURI(window.location.href)}`}
                        readOnly
                        onClick={onTextAreaTouch}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>닫기</Button>
            </DialogActions>
        </Dialog>
      </div>
    );
}

export default ShareDialog

// 이 대화상자가 iOS에서만 뜨면 됩니다
// 링크가 걸려야 할 버튼은 Header에 있습니다.
// iOS detect는
// const UA = window.navigator.userAgent.toLowerCase()
// if (/ipod|ipad|iphone/.test(UA)) 처럼 해서 Header에 들어있습니다.
// 지금 안되는건 style이 깨지는거랑 text를 가다듬는 것 정도인 것 같은데, 간단히 고쳐주시면 될 것 같아요


