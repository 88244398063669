import React from 'react';
import CustomSlider from './CustomSlider';
import LikertScale from './LikertScale';
import Selection from './Selection';
import FreeForm from './FreeForm';

export default function Response(props) {
    const { responseType, options, defaultValue } = props;
    return (
        <div>
            { responseType === 'slider' ? <CustomSlider defaultValue={options.defaultValue} min={options.min} max={options.max} step={options.step} unit={options.unit}/> : null }
            { responseType === 'likertscale' ? <LikertScale marks={options.marks} defaultValue={defaultValue || 3}/> : null }
            { responseType === 'multiple-choice' ? <Selection options={options} /> : null }
            { responseType === 'freeform' ? <FreeForm defaultValue={defaultValue || ''} /> : null }
        </div>
    )
}