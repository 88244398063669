import React from 'react';
import Slider from '@material-ui/core/Slider';

import { connect } from 'react-redux';
import { selectOption } from '../../actions';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        select: (option) => dispatch(selectOption(option))
    }
}

class CustomSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentValue: 0
        }
    }

    componentDidMount() {
        const { defaultValue, select } = this.props;
        this.setState({
            currentValue: defaultValue
        })
        select(defaultValue.toString());
    }

    handleSliderChange = (event, newValue) => {
        const { select } = this.props;
        this.setState({
            currentValue: newValue
        })
        select(newValue.toString());
    };
    

    render() {
        const { defaultValue, step, min, max, unit } = this.props;
        const { currentValue } = this.state;
        return (
            <div>
                <div className="text-center value">
                    {currentValue}{unit}
                </div>
                <Slider
                    className='customSlider'
                    defaultValue={defaultValue}
                    onChange={this.handleSliderChange}
                    aria-labelledby="discrete-slider"
                    step={step}
                    min={min}
                    max={max}
                    marks={[
                        {value:min, label: `${min}${unit}`}, 
                        {value:max, label: `${max}${unit}`}, 
                    ]}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomSlider);