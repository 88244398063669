import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Button from '../../components/Button';

import { connect } from 'react-redux';
import { selectIssue, saveIssueTitle, saveIssueKey, saveOpinion } from '../../actions';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, saveAPIToken, saveIssueTitle, saveIssueKey } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        token: saveAPIToken,
        issueTitle: saveIssueTitle,
        issueKey: saveIssueKey
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        opinion: (opinion) => dispatch(saveOpinion(opinion)),
        select: (issue) => dispatch(selectIssue(issue)),
        saveIssueTitle: (issue) => dispatch(saveIssueTitle(issue)),
        saveIssueKey: (key) => dispatch(saveIssueKey(key)),
    }
}

class IssueScreen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            issues: [], // {title, description, _id}
            prevOpinion: false
        }
        this.onNextClickHandler = this.onNextClickHandler.bind(this)
    }

    componentDidMount() {
        const { select, saveIssueTitle, saveIssueKey, token } = this.props;

        fetch(`${process.env.REACT_APP_URL}/dev/issues`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
            .then((res) => res.json())
            .then((response) => 
                this.setState({issues: response})    
            )
        select('');
        saveIssueTitle('');
        saveIssueKey('');
    }
    
    onClickHandler(selectedIssue, bl) {
        const { select, saveIssueTitle, saveIssueKey } = this.props;
        if (bl) {
            select(selectedIssue._id);
            saveIssueTitle(selectedIssue.title);
            saveIssueKey(selectedIssue.key);
            this.setState({
                prevOpinion: selectedIssue.prevOpinion
            })
        }
        else {
            select('');
            saveIssueTitle('');
            saveIssueKey('');
            this.setState({
                prevOpinion: false
            })
        }
    }

    onNextClickHandler() {
        const { issue, issueKey, opinion } = this.props
        const { prevOpinion } = this.state
        if (issue) {
            if (prevOpinion) {
                opinion('')
                console.log('asdf')
                this.props.history.push(`/result/${issueKey}`)
            } else {
                this.props.history.push('/select')
                console.log('qwer')
            }
        }
        // issue ? () => this.props.history.push('/select') : null
    }

    render() {
        const { issues } = this.state;
        const { issue, isMobile } = this.props;
        return (
            <div className="wrapper">
                <Header />
                <div className="container">
                    <div className="title text-light text-center issue-title">
                        원하시는 사회 이슈를 선택해 주세요.
                    </div>
                    {
                        issues.map((item, i) => {
                            return (<Button key={item._id} id={item._id} type={`button-transparent select button ${item.numOpinions >= 200 ? 'hot' : ''} ${item.prevOpinion ? 'done' : ''}`} content={isMobile ? `${item.shortTitle}` : `${item.title}`} onClick={(bl) => this.onClickHandler(item, bl)}/>)
                        })
                    }
                </div>
                <div className="next">
                    <Button type={`select button text-bold ${issue ? 'button-gradient' : 'disabled'}`} disabled={issue ? false : true} content={`${this.state.prevOpinion ? '의견 보기': '의견 남기기'}`} onClick={this.onNextClickHandler}/>
                </div>
            </div>
        )
    }
}

IssueScreen.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    issue: PropTypes.string
}

export default connect(mapStateToProps, mapDispatchToProps)(IssueScreen);