import React from 'react';
import { connect } from 'react-redux';
import Header from '../../components/Header';
import ShareDialog from '../../components/ShareDialog'
import Response from '../../components/Response'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import UpIcon from '@material-ui/icons/KeyboardArrowUp'
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import ReactGA from 'react-ga'
import EventDialog from '../../components/EventDialog'
import { selectIssue, saveIssueTitle, saveIssueKey, saveAPIToken, selectOption } from '../../actions';


const mapStateToProps = (state) => {
    const { setMediaFilter, selectOption, selectIssue, saveOpinion, saveIssueTitle, saveAPIToken } = state;
    return {
        opinion: saveOpinion,
        isMobile: "MOBILE" === setMediaFilter,
        option: selectOption,
        issue: selectIssue,
        issueTitle: saveIssueTitle,
        token: saveAPIToken,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        select: (issue) => dispatch(selectIssue(issue)),
        saveIssueTitle: (issue) => dispatch(saveIssueTitle(issue)),
        saveIssueKey: (key) => dispatch(saveIssueKey(key)),
        save_token: (token) => dispatch(saveAPIToken(token)),
        selectoption: (option) => dispatch(selectOption(option))
    }
}


class CompleteScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 'star',
            statistics: {
                opinionCount: 0, 
                genderCount: {'여성': 0, '남성': 0, '그 외': 0, '대답을 원치 않음': 0}, 
                ageCount: {'10대': 0, '20대': 0, '30대': 0, '40대': 0, '50대': 0, '60대': 0, '70대 이상': 0}, 
                stanceCount: {'보수': 0, '중도보수': 0, '중도': 0, '중도진보': 0, '진보': 0, '잘 모름/대답을 원치 않음': 0}
            },
            filters: ['정치성향별', '성별', '연령대별'],
            buttonIsHovered: false,
            buttonIsClicked: false,
            filter: ['정치성향별'],
            filterItem: '',
            mobileFilterPoliticalStance: ['정치성향별'],
            mobileFilterGender: ['성별'],
            mobileFilterAge: ['연령대별'],
            mobileFilterStance: ['입장별'],
            width: window.width,
            height: window.height,
            issueTitle: '',
            drawData: null,
            hoverTop: 0,
            hoverLeft: 0,
            hoverId: '',
            clickedId: '',
            opinions: [],
            opinions20: [],
            idx: -1,
            open: false, // snackbar open bool
            top20: false,
            myissue: true,
            loading: true,
            prevOpinion: false,
            copied: false,
            isEditing: false,
            newStance: -1,
            myOpinion: {},
            myOpinionIdx: -1,
            enableMobileView: false
        }
        this.filterGraph = this.filterGraph.bind(this);
        this.updateFilterAge = this.updateFilterAge.bind(this);
        this.updateFilterGender = this.updateFilterGender.bind(this);
        this.updateFilterPoliticalStance = this.updateFilterPoliticalStance.bind(this);
        this.updateFilterStance = this.updateFilterStance.bind(this);
        this.setButtonHovered = this.setButtonHovered.bind(this);
        this.setButtonClicked = this.setButtonClicked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
        this.onLikeClickHandler = this.onLikeClickHandler.bind(this);
        this.setOpen = this.setOpen.bind(this);
        this.updateTop20 = this.updateTop20.bind(this);
        this.createLog = this.createLog.bind(this)
        this.updateMyIssue = this.updateMyIssue.bind(this);
    }

    setOpen(bool) {
        this.setState({
            open: bool
        })
    }

    filterGraph(filter, statistics) {
        const filterList = (
            filter[0] ==="정치성향별" ? [['보수', 'ex-r'],['중도보수', 'r'],['중도', 'n'],['중도진보', 'l'],['진보', 'ex-l'],['잘 모름/대답을 원치 않음', 'not']]
            : filter[0] ==="성별" ? [['여성', 'female'], ['남성', 'male'], ['그 외', 'un'], ['대답을 원치 않음', 'not']]
            : [['10대', 'g10'], ['20대', 'g20'],['30대', 'g30'],['40대', 'g40'],['50대', 'g50'],['60대', 'g60'],['70대 이상', 'g70']]
        )
        const statsKey = {
            '정치성향별': 'stanceCount',
            '성별': 'genderCount',
            '연령대별': 'ageCount'
        }[filter[0]]
        const filterType = filter[0]
        const { filterItem } = this.state
        return (
            (
                <div className="filter-group">
                    <div className="filter-text" onClick={(e) => this.graphOnClickHandler(filterType, '', e)}>
                        총 {statistics.opinionCount}명 중
                    </div>
                    {
                        filterList.map(filter => {
                            return (
                            <div className="filter-item" key={filter[0]} onClick={(e) => this.graphOnClickHandler(filterType, filter[0], e)}>
                                <div className={`${filter[1]} circular filter`} />
                                <div className={`filter-text ${filterItem === filter[0] ? 'active' : ''}`}>
                                    {filter[0]} {statistics[statsKey][filter[0]]}명
                                </div>
                            </div>
                            )})
                    }
                </div>
            )
        )
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setOpen(false);
    };

    createLog (eventName, payload) {
        const { token, match } = this.props
        fetch(`${process.env.REACT_APP_URL}/dev/logs`, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body: JSON.stringify([{
                page: `result/${match.params.key}`,
                event: eventName,
                timestamp: Date.now(),
                payload: payload
            }])
        })
    }

    updateFilterPoliticalStance(event) {
        console.log('politicalstance filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterPoliticalStance', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterPoliticalStance: [event.target.value]
        })
    }

    updateFilterGender(event) {
        console.log('gender filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterGender', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterGender: [event.target.value]
        })
    }

    updateFilterAge(event) {
        console.log('age filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterAge', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterAge: [event.target.value]
        })
    }

    updateFilterStance(event) {
        console.log('stance filter changed in mobile to', event.target.value);
        this.createLog('UpdateMobileFilterStance', {
            changedTo: event.target.value
        })
        this.setState({
            mobileFilterStance: [event.target.value]
        })
    }

    updateTop20(bl) {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            this.setState({
                loading: false
            })
        }, 500)
        this.setState({
            top20: bl
        })
    }

    updateMyIssue(bl) {
        this.setState({
            myissue: bl
        })
    }

    onMouseEnterHandler(data, top, left, idx) {
        this.setState({
            drawData: data,
            hoverTop: top,
            hoverLeft: left,
            hoverId: data._id,
            idx: idx
        })
    }

    onStarClickHandler(id) {
        console.log('star is clicked', id);
        this.createLog('StarClicked', {
            opinionId: id
        })
        this.setState({
            clickedId: id
        })
    }

    onMouseLeaveHandler() {
        const { hoverId, clickedId } = this.state;
        console.log('mouse left', hoverId, clickedId)
        if ( hoverId !== clickedId ) {
            this.setState({
                drawData: null,
                hoverTop: 0,
                hoverLeft: 0,
                hoverId: null,
            })
        }
    }

    onUpdateOpinionClick = () => {
        const { prevOpinion } = this.state
        if (prevOpinion) {
            this.props.history.push('/UpdateOpinion')
        } else {
            const UA = window.navigator.userAgent.toLowerCase()
            if (navigator.userAgent.includes('wv') ||
                    (/iphone|ipod|ipad/.test(UA) && !/safari/.test(UA))){
                    alert(`카카오톡이나 페이스북 등 앱에서 접속하신 경우 카카오 계정 로그인 등 일부 기능이 동작하지 않을 수 있습니다. \n오른쪽 위 메뉴 (안드로이드) 혹은 오른쪽 아래 공유하기 (iOS) 에서 다른 브라우저로 접속해주세요!`)
                }
            this.props.history.push('/consent')
        }
    }

    onSeeOtherIssueClick = () => {
        const { prevOpinion } = this.state
        if (prevOpinion) {
            this.props.history.push('/NewComplete')
        } else {
            this.props.history.push('/other')
        }
    }

    resultHeader() {
        const { issueTitle, isMobile, token } = this.props;
        const { value, mobileFilterPoliticalStance, mobileFilterGender, mobileFilterAge, mobileFilterStance, prevOpinion, enableMobileView} = this.state;
        const politicalStance = [['보수', 'ex-r'],['중도보수', 'r'],['중도', 'n'],['중도진보', 'l'],['진보', 'ex-l'],['잘 모름/대답을 원치 않음', 'not']];
        const gender =  [['여성', 'female'], ['남성', 'male'], ['그 외', 'un']];
        const age = [['10대', 'g10'], ['20대', 'g20'],['30대', 'g30'],['40대', 'g40'],['50대', 'g50'],['60대', 'g60'],['70대 이상', 'g70']];
        const stanceList = [[1,'매우 반대'],[2, '반대'],[3, '중립'],[4, '찬성'],[5, '매우 찬성']];
        return(
            <div>
                <div className="result-header text-center">
                    <div className="issue-title">
                        { issueTitle }
                    </div>
                    {
                        !prevOpinion && 
                        <div className="result-action">
                            <span onClick={this.onUpdateOpinionClick} className="white underline link">{prevOpinion ? (prevOpinion.argument ? '이 이슈에 대한 내 생각 수정하기' : '이 이슈에 대한 내 생각 남기기' ) : '내 생각 남기기'}</span>
                        </div>
                    }
                    <div className="result-action" onClick={this.onSeeOtherIssueClick}>
                        <span onClick={this.onSeeOtherIssueClick} className="white underline link">다른 이슈 결과보기</span>
                    </div>
                </div>
                {
                    isMobile ?
                    <div>
                        <div className="filter-button-wrapper">
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={mobileFilterPoliticalStance}
                                            onChange={this.updateFilterPoliticalStance}
                                            input={<Input id="select-multiple"/>}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "정치성향별"
                                                    else return mobileFilterPoliticalStance[0]
                                                }
                                            }
                                            >
                                                <MenuItem value='전체'>
                                                    전체
                                                </MenuItem>
                                            {
                                                politicalStance.map(stance => (
                                                    <MenuItem key={stance[0]} value={stance[0]}>
                                                        {stance[0]}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={mobileFilterGender}
                                            onChange={this.updateFilterGender}
                                            input={<Input id="select-multiple" />}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "성별"
                                                    else return mobileFilterGender[0]
                                                }
                                            }
                                            >
                                                <MenuItem value='전체'>
                                                    전체
                                                </MenuItem>
                                            {gender.map(g => (
                                                <MenuItem key={g[0]} value={g[0]}>
                                                    {g[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="filter-button-wrapper">
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={mobileFilterAge}
                                            onChange={this.updateFilterAge}
                                            input={<Input id="select-multiple" />}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "연령대별"
                                                    else return mobileFilterAge[0]
                                                }
                                            }
                                            >
                                                <MenuItem value='전체'>
                                                    전체
                                                </MenuItem>
                                            {age.map(a => (
                                                <MenuItem key={a[0]} value={a[0]}>
                                                    {a[0]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="dropdown-button">
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={'입장별'}
                                            onChange={this.updateFilterStance}
                                            input={<Input id="select-multiple" />}
                                            renderValue={
                                                selected => {
                                                    if ( selected.length === 0 ) return "입장별"
                                                    else {
                                                        if (mobileFilterStance[0] === '입장별') return "입장별"
                                                        else if (mobileFilterStance[0] === '전체') return "전체"
                                                        else return stanceList[mobileFilterStance[0]-1][1]
                                                    }
                                                }
                                            }
                                            >
                                                <MenuItem value='전체'>
                                                    전체
                                                </MenuItem>
                                            {stanceList.map(a => (
                                                <MenuItem key={a[0]} value={a[0]}>
                                                    {a[1]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    (
                        value === 'star' ? 
                        <div className="labels">
                            <div className="left-label"> 매우 반대 </div>
                            <div className="right-label"> 매우 찬성 </div>
                            <div className="axis"></div>
                        </div>
                        : ''
                    )
                    
                }
            </div>
        )
    }

    componentWillUnmount() {
        console.log('fire')
        window.removeEventListener('resize', this.timeoutedHandleWindowSizeChange);
        window.removeEventListener('mousedown', this.handleMouseDown);
        document.querySelector('meta[name="viewport"]').setAttribute("content", 'width=device-width, maximum-scale=1');
    }

    handleMouseDown = (event) => {
        if ( event.target && event.target.className )
        {
            if (typeof event.target.className.baseVal === 'string') {
                return
            }
            const _className = event.target.className;
            if (!(( typeof(_className) === 'string') && ( _className.includes('circular') || _className.includes('like-button') || _className.includes('heart') || _className.includes('text') || _className.includes('command') || _className.includes('Mui') || _className.includes('outerCircle') || _className.includes('freeform-input')))) {
                this.setState({
                    drawData: null,
                    hoverTop: 0,
                    hoverLeft: 0,
                    hoverId: null,
                })
            }
        }
    }

    handleWindowSizeChange = () => {
        const { isMobile } = this.props;
        if ( !(isMobile) ) {
            const width = document.getElementsByClassName('opinion-container')[0].offsetWidth;
            const height = document.getElementsByClassName('opinion-container')[0].offsetHeight;
            this.setState({ width: width, height: height });
        }
    };

    onLikeClickHandler(data, idx) {
        const { _id, liked } = data;
        console.log("like clicked", data, "liked", !liked);
        if (!liked) {
            this.createLog('OpinionLiked', {
                opinionId: _id,
                liked: !liked
            })
        } else {
            this.createLog('opinionUnliked', {
                opinionId: _id,
                liked: !liked
            })
        }

        const { opinion, token } = this.props;
        const { opinions, myOpinion } = this.state;
        if (!liked) {
            fetch(`${process.env.REACT_APP_URL}/dev/opinions/addEdge`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    authorOpinionId: myOpinion._id, 
                    likedOpinionId: _id, 
                })
            })
            .then(() => {
                if (myOpinion._id === _id) {
                    this.setState({
                        myOpinion: {...myOpinion, liked: true, likeCount: myOpinion.likeCount + 1}
                    })
                }
                for (var i = 0; i < opinions.length; ++i) {
                    if (opinions[i]._id === _id) {
                        opinions[i].liked = true;
                        opinions[i].likeCount += 1
                        this.setState({
                            opinions: opinions
                        })
                    }
                }
            })
        }
        else {
            fetch(`${process.env.REACT_APP_URL}/dev/opinions/removeEdge`, {
                method: 'post',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type':'application/json'
                },
                body: JSON.stringify({
                    authorOpinionId: myOpinion._id, 
                    unlikedOpinionId: _id, 
                })
            })
            .then(() => {
                if (myOpinion._id === _id) {
                    this.setState({
                        myOpinion: {...myOpinion, liked: false, likeCount: myOpinion.likeCount - 1}
                    })
                }
                for (var i = 0; i < opinions.length; ++i) {
                    if (opinions[i]._id === _id) {
                        opinions[i].liked = false;
                        opinions[i].likeCount -= 1
                        this.setState({
                            opinions: opinions
                        })
                    }
                }
            })
        }
    }

    setEditing = (data) => {
        const { isEditing, newStance, opinions, myOpinion } = this.state
        const { token, issue, option } = this.props
        if (isEditing) {
            this.createLog('updateOpinion', {
                prevOpinion: data,
                newOpinion: {
                    issue: issue,
                    stance: newStance,
                    argument: option
                }
            })
            fetch(`${process.env.REACT_APP_URL}/dev/opinions?issueId=${issue}`, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    stance: newStance, // 정책에 대한 5-point Likert scale (1: 매우 반대, 5: 매우 찬성)
                    argument: option, // "왜 그렇게 생각하셨나요?"에 대한 대답.
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    this.setState({
                        opinions: opinions.map(
                            (o) => o.mine
                                ? {...response, 
                                    mine: o.mine, 
                                    author: o.author, 
                                    likeCount: response.likedBy.length,
                                    liked: response.likes.includes(o._id)}
                                : o
                        ),
                        myOpinion: {
                            ...response,
                            mine: myOpinion.mine,
                            author: myOpinion.author,
                            likeCount: response.likedBy.length,
                            liked: response.likes.includes(myOpinion._id)
                        }
                    })
                    this.setState({
                        drawData: null,
                        hoverTop: 0,
                        hoverLeft: 0,
                        hoverId: null,
                    })
                })
        }
        this.setState({
            isEditing: !isEditing
        })
    }

    resultBox(data, top, left, idx) {
        const { mobileFilterAge, mobileFilterGender, mobileFilterPoliticalStance, mobileFilterStance, isEditing, opinions, myOpinionIdx, newArgument } = this.state;
        const { token, issue, option } = this.props;
        const _top = top ? top : 0;
        const _left = left ? left : 0;
        const relative = top ? 'absolute' : '';
        const marks = ['매우 반대', '반대', '중립', '찬성', '매우 찬성']

        // const onTextBoxChange = (e) => {
        //     this.setState({
        //         newArgument: e.target.value
        //     })
        // }

        const handleSliderChange = (e, newValue) => {
            this.setState({
                newStance: newValue
            })
        }
        return (
            data && data.author &&
                ((mobileFilterAge[0] === '연령대별') || (mobileFilterAge[0] === '전체') || (mobileFilterAge[0] === data.author.age)) &&
                ((mobileFilterGender[0] === '성별') || (mobileFilterGender[0] === '전체') || (mobileFilterGender[0] === data.author.gender)) &&
                ((mobileFilterPoliticalStance[0] === '정치성향별') || (mobileFilterPoliticalStance[0] === '전체') || (mobileFilterPoliticalStance[0] === data.author.politicalStance)) &&
                ((mobileFilterStance[0] === '입장별') || (mobileFilterStance[0] === '전체') || (mobileFilterStance[0] == data.stance))
                ?
                <div key={data._id + data.stance + data.argument} className={`result-box ${data.mine && isEditing && 'edit'}`} style={{top:_top, left: _left, position: relative}}>
                    <div className="opinion-box">
                        <div className="opinion white">
                        {
                            data.mine &&
                            <div className="opinion-header">
                                <div>
                                    [내 생각]
                                </div>
                                <div onClick={() => this.setEditing(data)}>
                                    {isEditing ? 
                                    <CheckIcon className="command-div"/> : 
                                        (
                                            !data.argument ? 
                                            // <Button
                                            //     variant="contained"
                                            //     size="small"
                                            //     className="command"
                                            //     startIcon={<EditIcon/>}
                                            //     >의견 남기기</Button>
                                            // : <Button
                                            //     variant="contained"
                                            //     size="small"
                                            //     className="command"
                                            //     startIcon={ <EditIcon/> }
                                            //     >의견 고치기</Button>
                                            <div className="command-div">
                                                <div className="command">
                                                생각 남기기
                                                </div>
                                                <EditIcon/>
                                            </div>
                                            : 
                                            <div className="command-div">
                                                <div className="command">생각 수정하기
                                                </div>
                                                <EditIcon></EditIcon>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            (data.mine && isEditing) ?
                            <div className="command-edit">
                                <Slider
                                    className='likertscale'
                                    defaultValue={data.stance}
                                    aria-labelledby="discrete-slider-custom"
                                    valueLabelDisplay="auto"
                                    onChange={handleSliderChange}
                                    min={1}
                                    max={5}
                                    marks={marks.map((value,i) => {
                                            return {value: i+1, label: value}
                                        })
                                    }></Slider>
                                {/* <Response responseType={"likertscale"} options={{marks: marks}} defaultValue={data.stance}/> */}
                                <Response className="text" responseType={"freeform"} defaultValue={data.argument}/>
                                {/* <textarea defaultValue={data.argument} 
                                    className="text freeform-input"
                                    placeholder="이 이슈에 대해 그렇게 생각하시는 이유를 알려주세요."
                                    onChange={onTextBoxChange}
                                    value={newArgument}></textarea> */}
                            </div> :
                            <div>
                                &lt;{
                                    marks[data.stance-1]
                                }&gt;
                            <br></br>
                            { data.argument }
                            </div>
                        }
                        {
                            data.mine && !data.argument && !isEditing && 
                                <div className="text-center placeholder">'생각 남기기'를 누르시고 이 이슈에 대해  <br></br>그렇게 생각하시는 이유를 알려주세요.</div>
                        }
                        </div>
                        <div className="demographics">
                            <div className="demographic pol">
                                <div className={`list ${ data.author.politicalStance ==='보수' ? 'ex-r' : data.author.politicalStance ==='중도보수' ? 'r' : data.author.politicalStance ==='중도' ? 'n' : data.author.politicalStance ==='중도진보' ? 'l' : data.author.politicalStance ==='진보' ? 'ex-l' : 'not' } circular`} />
                                <div className="text">
                                    {data.author.politicalStance}
                                </div>
                            </div>
                            <div className="demographic age">
                                <div className={`list ${ data.author.age ==='60대' ? 'g60' : data.author.age ==='J0대' ? 'g10' : data.author.age ==='20대' ? 'g20' : data.author.age ==='30대' ? 'g30' : data.author.age ==='40대' ? 'g40' : data.author.age ==='50대' ? 'g50' :'g70' } circular`} />
                                <div className="text">
                                    {data.author.age}
                                </div>
                            </div>
                            <div className="demographic gender">
                                <div className={`list ${data.author.gender ==='여성' ? 'female' : data.author.gender ==='남성' ? 'male' : 'un'} circular`} />
                                <div className="text">
                                    {data.author.gender}
                                </div>
                            </div>
                            <div className="demographic occ">
                                <div className="text">
                                    [{data.author.occupation}]
                                </div>
                            </div>
                        </div>
                        <div className="divider" />
                        <div className="like text-center">
                            <div className="text">{data.likeCount}명이 공감하였습니다.</div>
                            <div className="like-button" onClick={() => this.onLikeClickHandler(data, idx)}>
                            <div className={`heart ${data.liked ? 'heart-selected' : 'heart-unselected'}`}/>
                                <div className="text">공감</div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            null
    )}

    componentDidMount() {
        const { match, token } = this.props;
        const { select, saveIssueTitle, saveIssueKey, selectoption } = this.props;

        const key = match.params.key
        let url = ''

        selectoption('')

        // document.querySelector('meta[name="viewport"]').setAttribute("content", 'width=device-width, initial-scale=1, maximum-scale=1');

        // if (issue) {
        //     url = `${process.env.REACT_APP_URL}/dev/opinions?issueId=${issue}&opinionId=${opinion}`
        // } else  

        if (!token) {
            const emailId = Math.random().toString(36).substring(2);
            const kakaoId = Math.random().toString(9).substring(7);
            const email = emailId + '@dummy.com';
            ReactGA.set({ userId: kakaoId });

            fetch(`${process.env.REACT_APP_URL}/dev/users`, {
                method: 'post',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    email: email, 
                    kakaoId: kakaoId,
                })
            })
            .then((res) => res.json())
            .then((response) => {
                this.props.save_token(response.token);
                localStorage.setItem('dummy', true);
                // this.props.history.push('/consent');
            }, (error) => {
                if (error) {
                    console.log(error);
                }
            })
        }
        if (match.params.key) {
            url = `${process.env.REACT_APP_URL}/dev/opinions?key=${key}`

            fetch(`${process.env.REACT_APP_URL}/dev/issues/key?key=${key}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then(res => res.json())
                .then(response => {
                    select(response.issue._id  || null)
                    saveIssueTitle(response.issue.title || null)
                    saveIssueKey(response.issue.key || null)
                    this.setState({
                        prevOpinion: response.prevOpinion
                    })
                    localStorage.setItem('prevOpinion', JSON.stringify(response.prevOpinion));
                })
        }

        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type':'application/json'
            }
        })
            .then((res) => res.json())
            .then((response) => 
                this.setState({opinions: response, loading: false},
                    () => {
                        const { opinions } = this.state;
                        const opinionCount = opinions.length;
                        const genderCount = {'여성': 0, '남성': 0, '그 외': 0, '대답을 원치 않음': 0};
                        const ageCount = {'10대': 0, '20대': 0, '30대': 0, '40대': 0, '50대': 0, '60대': 0, '70대 이상': 0};
                        const stanceCount = {'보수': 0, '중도보수': 0, '중도': 0, '중도진보': 0, '진보': 0, '잘 모름/대답을 원치 않음': 0, };
                        opinions.length > 0 && opinions.map(data => {
                            if (data.author) {
                                genderCount[data.author.gender] = genderCount[data.author.gender]+1;
                                ageCount[data.author.age] = ageCount[data.author.age]+1;
                                stanceCount[data.author.politicalStance] = stanceCount[data.author.politicalStance]+1;    
                            }
                            return 0;
                        })
                        const myOpinionIdx = opinions.findIndex(o => o.mine)
                        if (myOpinionIdx >= 0) {
                            const myOpinion = opinions[myOpinionIdx]
                            const stance = myOpinion.stance-1;
                            const left = stance>0 ? width/5*stance + width/5*(myOpinion.x/500) : width/5*(myOpinion.x/500);
                            const top = height*(myOpinion.y/1000) ? height*(myOpinion.y/1000) : 0;
                            const hoverLeft = width/2 < left ? left-256-20 : left;
                            const hoverTop = height/2 < top ? height-256-20 : top;
                            this.setState({
                                drawData: myOpinion,
                                hoverTop: hoverTop,
                                hoverLeft: hoverLeft,
                                hoverId: myOpinion._id,
                                idx: myOpinionIdx,
                                newStance: myOpinion.stance,
                                myOpinion: myOpinion,
                                myOpinionIdx: myOpinionIdx
                            })
                        }
                        
                        this.setState({
                            width: width - 24,
                            height: height - 24,
                            statistics: {
                                opinionCount: opinionCount,
                                genderCount: genderCount,
                                ageCount: ageCount,
                                stanceCount: stanceCount,
                            }
                        })
                    }
            ))
        const { isMobile } = this.props;
        const UA = navigator.userAgent

        this.setState({
            enableMobileView: UA.includes('Mobi')
        })
        var width, height;
        if (isMobile) {    
            width = 0; 
            height = 0;
        }
        else { 
            width = document.getElementsByClassName('opinion-container')[0].offsetWidth;
            height = document.getElementsByClassName('opinion-container')[0].offsetHeight;
        }
        this.createLog('IssueViewed', {
            issue: key
        })
        window.addEventListener('resize', this.timeoutedHandleWindowSizeChange);
        window.addEventListener('mousedown', this.handleMouseDown);
    }

    timeoutedHandleWindowSizeChange = () => {
        return setTimeout(this.handleWindowSizeChange, 100)
    }

    setButtonHovered(bl) {
        this.setState({
            buttonIsHovered: bl
        })
    }

    filterOnClickHandler(item, e) {
        const filterList = {
            '보수': 'ex-r', '중도보수': 'r', '중도': 'n', '중도진보': 'l', '진보': 'ex-l','잘 모름/대답을 원치 않음': 'not',
            '여성': 'female', '남성': 'male', '그 외': 'un',
            '10대': 'g10', '20대': 'g20','30대': 'g30','40대': 'g40','50대': 'g50','60대': 'g60','70대 이상': 'g70'
        }
    }

    graphOnClickHandler(filterType, item,e) {
        const { filterItem } = this.state
        console.log('donut graph segment is clicked', item, 'active?', !(filterItem === item));
        // console.log(e)

        if (filterType === '성별') {
            this.createLog('UpdateDesktopFilterGender', {
                changedTo: item,
                isActive: !(filterItem === item)
            })
        } else if (filterType === '정치성향별') {
            this.createLog('UpdateDesktopFilterPoliticalStance', {
                changedTo: item,
                isActive: !(filterItem === item)
            })
        } else {
            this.createLog('UpdateDesktopFilterAge', {
                changedTo: item,
                isActive: !(filterItem === item)
            })
        }

        if (!(filterItem === item)) {
            this.setState({
                filterItem: item
            })
        } else {
            this.setState({
                filterItem: ''
            })
        }

        // const filterList = {
        //     '보수': 'ex-r', '중도보수': 'r', '중도': 'n', '중도진보': 'l', '진보': 'ex-l','잘 모름/대답을 원치 않음': 'not',
        //     '여성': 'female', '남성': 'male', '그 외': 'un',
        //     '10대': 'g10', '20대': 'g20','30대': 'g30','40대': 'g40','50대': 'g50','60대': 'g60','70대 이상': 'g70'
        // }
        // if (e.target.classList.contains('active')) {
        //     Array.from(document.querySelectorAll(`.circular:not(.filter):not(.list):not(.${filterList[item]})`)).forEach((elm, idx) => {
        //         elm.parentElement.style.display = ''
        //     });
        //     e.target.classList.remove('active');
        // }
        // else {
        //     Array.from(document.querySelectorAll(`.circular:not(.filter):not(.list):not(.${filterList[item]})`)).forEach((elm, idx) => {
        //         elm.parentElement.style.display = 'none';
        //     });
        //     e.target.classList.add('active');
        // }
    }

    drawGraph(filter) {
        const colorSet = {'보수': "#ff71a9", '중도보수': "#ff8975", '중도': "#fff368", '중도진보': "#86daff", '진보': "#29a3ff", '잘 모름/대답을 원치 않음': "#7B7B7B",
                        '여성': "#ff705a", '남성': "#63bcff", '그 외': "#fff25d", '대답을 원치 않음': '#7b7b7b', 
                        '10대': '#ffffff', '20대': "tomato", '30대': "orange", '40대': "dodgerblue", '50대': "mediumseagreen", '60대': "slateblue", '70대 이상': 'violet'}

        var totalOffset = 0;
        const initialOffset = 25;
        const { statistics, filterItem } = this.state;
        var list = [];
        var strokeDashoffset=0;
        var strokeDasharray='0 0';
        const opinionCount = statistics.opinionCount;
        if (filter[0] === '정치성향별') list = statistics.stanceCount;
        else if (filter[0] === '성별') list = statistics.genderCount;
        else list = statistics.ageCount;

        return (
            <svg width="70%" height="70%" viewBox="0 0 42 42" className="donut">
                {
                    opinionCount !== 0 ?
                    Object.keys(list).map((item, idx) => {
                        if (list[item] !== 0) {
                            if (idx === 0) {
                                strokeDashoffset = initialOffset
                            } else { 
                                strokeDashoffset = (100 - totalOffset + initialOffset > 100 ?
                                                    100 - totalOffset + initialOffset - 100 : 
                                                    100 - totalOffset + initialOffset).toString();
                            }
                            var currentOffset = list[item] / opinionCount * 100;
                            var remainingOffset = 100 - currentOffset;
                            totalOffset = totalOffset + currentOffset;
                            strokeDasharray = `${currentOffset} ${remainingOffset}`;
                            return <circle key={idx}
                                className={`donut-segment ${filterItem === item ? 'active' : ''}`}
                                cx="21"
                                cy="21"
                                r="15.91549430918954"
                                fill="none"
                                stroke={colorSet[item]}
                                strokeWidth="6"
                                strokeDasharray={strokeDasharray}
                                style={{ strokeDashoffset: strokeDashoffset }}
                                onClick={(e) => this.graphOnClickHandler(filter[0], item, e)} />
                        }
                        return 0;
                    })
                    : null
                }

            </svg>
        )
    }    

    setButtonClicked() {
        const { buttonIsClicked } = this.state;
        this.setState({
            buttonIsClicked: !buttonIsClicked
        })
        this.props.onClick(!buttonIsClicked);
    }

    handleChange(event) {
        console.log('the view mode changed to: ', event.target.value);
        this.createLog('ViewModeChanged', {
            changedTo: event.target.value,
        })
        this.setState({value: event.target.value});
    }

    handleFilterChange(event) {
        console.log('filter on the left set to', event.target.value);
        this.createLog('FilterChanged', {
            changedTo: event.target.value,
        })
        this.setState({
            filter: [event.target.value],
        })
    }

    scrollToTop = (event) => {
        const target = document.getElementById('target')
        target.scrollIntoView({behavior: 'smooth', block: 'center'})
        // scroll.scrollToTop()
        console.log('asdf')
        // document.getElementsByClassName('relaxed-container')[0].scrollTo(0, 0);
    }

    shareDialog = () => {
        console.log('asdf')
        this.setOpen(true)
    }

    onCopied = () => {
        this.setState({
            copied: true
        })
    }

    onCopyDialogClose = () => {
        this.setOpen(false)
        this.setState({
            copied: false
        })
    }

    render() {
        const { isMobile } = this.props;
        const { opinions, value, filters, filter, width, idx, open, top20, myissue,
            height, drawData, hoverTop, hoverLeft, hoverId, issueTitle, 
            statistics, filterItem, loading, copied, prevOpinion, myOpinion, myOpinionIdx, enableMobileView } = this.state;
        const filteredOpinions = opinions ? opinions.filter(o => {
            return (o.argument && o.argument.length >= 10) 
        }) : []
            return (
            <div className="outer-container">
                <Header isMobile={isMobile} 
                    enableMobileView={enableMobileView} 
                    onShareClick={this.shareDialog}
                    onMobileViewToggleClick={this.toggleMobileView}/>
                <EventDialog></EventDialog>
                { isMobile ?
                    <div className="relaxed-container">
                        {this.resultHeader()}
                        <div className="opinion-container">
                            <div name="target" id="target"></div>
                            {myOpinion && this.resultBox(myOpinion, null, null, myOpinionIdx)}
                            {   
                                (filteredOpinions.length>0 && !loading) ?
                                opinions.filter(o => {
                                    return (o.argument && o.argument.length >= 10 && !o.mine)
                                }).map((value, idx) => {
                                    return this.resultBox(value, null, null, idx)
                                })
                                :
                                (
                                    loading ? 
                                    <div className="circular-progress-wrapper">
                                        <CircularProgress color="secondary"/>
                                    </div>
                                    :
                                    <div className="no-opinion">
                                        의견이 아직 없습니다 :( 
                                            <br></br>
                                        '{prevOpinion ? (!prevOpinion.argument && '생각 남기기' ) : '내 생각 남기기'}'에서 이슈에 대한 자세한 생각을 남겨주세요!
                                    </div>
                                )
                            }
                            <Fab color="primary" size="small" onClick={this.scrollToTop}><UpIcon /></Fab>
                        </div>
                        <ShareDialog onClose={this.onCopyDialogClose} open={open} copied={copied} onCopied={this.onCopied}/>
                    </div>
                    :
                    <div className="relaxed-container">
                        <Grid container spacing={3}>
                            <Grid item xs={2} className="filter-column">
                                <div className="radio-filter">
                                    <RadioGroup aria-label="gender" name="gender1" value={value} onChange={this.handleChange}>
                                        <FormControlLabel value="star" control={<Radio />} label="의견 별별보기" />
                                        <FormControlLabel value="list" control={<Radio />} label="의견 리스트보기" />
                                    </RadioGroup>
                                </div>
                                {
                                    (opinions && opinions[0] && opinions[0].score) ?
                                    <FormControlLabel
                                        control={<Checkbox checked={top20} onChange={(e) => this.updateTop20(e.target.checked)} value="top20" />}
                                        label="추천 의견 모아보기"
                                    /> 
                                    : null
                                }
                                <br/>
                                {
                                    (opinions && opinions[0] && opinions[0].score) ?
                                        ((value === 'list') ?
                                        null :
                                        <FormControlLabel
                                            control={<Checkbox checked={myissue} onChange={(e) => this.updateMyIssue(e.target.checked)} value="myissue" />}
                                            label="내 의견 보기"
                                        />)
                                    : null
                                }
                                <div className="dropdown-filter">
                                    <FormControl>
                                        <Select
                                            value={filter}
                                            onChange={this.handleFilterChange}
                                            input={<Input id="select-multiple" />}
                                            >
                                            {filters.map(_filter => (
                                                <MenuItem key={_filter} value={_filter}>
                                                    {_filter}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {this.filterGraph(filter, statistics)}
                                    {this.drawGraph(filter)}
                                </div>
                                <div className="divider" />

                                {/* <a className="text link" href="/other">다른 이슈 결과보기 ></a> */}
                                <PopupState variant="popover" popupId="popup-popover">
                                    {popupState => (
                                        <div>
                                            <div className="text link" {...bindTrigger(popupState)}>SNS에 공유하기 ></div>
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <div className="popup">
                                                <p>공유하기를 원하시면 아래 링크를 클릭하세요.</p>
                                                <textarea
                                                    className="text-center"
                                                    value={`${decodeURI(window.location.href)}`}
                                                    readOnly
                                                    onClick={(e) => {
                                                        e.target.select();
                                                        document.execCommand('copy');
                                                        e.target.focus()
                                                        this.setOpen(true);
                                                    }}    
                                                />
                                            </div>
                                            <Snackbar
                                                anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                                }}
                                                open={open}
                                                autoHideDuration={2000}
                                                onClose={this.handleClose}
                                                ContentProps={{
                                                    'aria-describedby': 'message-id',
                                                }}
                                                message={<span id="message-id">링크가 복사되었습니다.</span>}
                                                action={[
                                                <IconButton
                                                    key="close"
                                                    aria-label="close"
                                                    color="inherit"
                                                    onClick={this.handleClose}
                                                >
                                                    <CloseIcon />
                                                </IconButton>,
                                                ]}
                                            />
                                        </Popover>
                                        </div>
                                    )}
                                </PopupState>
                            </Grid>
                            <Grid className="opinion-visualization" item xs={10}>
                                {this.resultHeader()}
                                {
                                    value === 'list' ?
                                    <div className="opinion-container justify-center">
                                        {myOpinion && this.resultBox(myOpinion, null, null, myOpinionIdx)}
                                        {
                                            (filteredOpinions.length > 0 && !loading) ?
                                            (
                                                opinions.filter(o => {
                                                    return (o.argument && o.argument.length >= 10) || o.mine
                                                }).filter((value, idx) => {
                                                    if ((top20 && idx<20) || (!top20)) {
                                                        if (!filterItem || // empty filter item
                                                            (filter[0] === '정치성향별' && value.author.politicalStance === filterItem) || // 정치성향 match
                                                            (filter[0] === '성별' && value.author.gender === filterItem) ||
                                                            (filter[0] === '연령대별' && value.author.age === filterItem)) {
                                                                if (value.mine) {
                                                                    return false
                                                                }
                                                                return true
                                                        }
                                                    }
                                                    return false
                                                }).map((value, idx) => {
                                                    return this.resultBox(value, null, null, idx)
                                                    // if ((top20 && idx<20) || (!top20)) {
                                                    //     if (!filterItem || // empty filter item
                                                    //         (filter[0] === '정치성향별' && value.author.politicalStance === filterItem) || // 정치성향 match
                                                    //         (filter[0] === '성별' && value.author.gender === filterItem) ||
                                                    //         (filter[0] === '연령대별' && value.author.age === filterItem)) {
                                                    //             return this.resultBox(value, null, null, idx)
                                                    //     }
                                                    // }
                                                })
                                            )
                                            :
                                            (
                                                loading ? 
                                                <div className="circular-progress-wrapper">
                                                    <CircularProgress color="secondary"/>
                                                </div>
                                                :
                                                <div className="no-opinion">
                                                    의견이 아직 없습니다 :( 
                                                        <br></br>
                                                    '{prevOpinion ? (!prevOpinion.argument && '생각 남기기' ) : '내 생각 남기기'}'에서 이슈에 대한 자세한 생각을 남겨주세요!
                                                </div>
                                            )
                                        }  
                                    </div>
                                    :
                                    <div className="opinion-container">
                                        {this.resultBox(drawData, hoverTop, hoverLeft, idx)}
                                        {   height && width && opinions.length >0 ?
                                            (
                                                opinions.map((data, idx) => {
                                                    if (data.author && (data.mine || (top20 && idx<20) || (!top20))) {
                                                        if (!filterItem || // empty filter item
                                                            (filter[0] === '정치성향별' && data.author.politicalStance === filterItem) || // 정치성향 match
                                                            (filter[0] === '성별' && data.author.gender === filterItem) ||
                                                            (filter[0] === '연령대별' && data.author.age === filterItem)) {
                                                            var circularType = '';
                                                            const stance = data.stance-1;
                                                            const left = stance>0 ? width/5*stance + width/5*(data.x/500) : width/5*(data.x/500);
                                                            const top = height*(data.y/1000) ? height*(data.y/1000) : 0;
                                                            const hoverLeft = width/2 < left ? left-256-20 : left;
                                                            const hoverTop = height/2 < top ? height-256-20 : top;
                                                            if (filter[0] === '정치성향별') circularType = data.author.politicalStance ==='보수' ? 'ex-r' : data.author.politicalStance ==='중도보수' ? 'r' : data.author.politicalStance ==='중도' ? 'n' : data.author.politicalStance ==='중도진보' ? 'l' : data.author.politicalStance ==='진보' ? 'ex-l' : 'not';
                                                            else if (filter[0] === '성별') circularType = data.author.gender ==='여성' ? 'female' : data.author.gender ==='남성' ? 'male' : data.author.gender ==='그 외' ? 'un' : 'not';
                                                            else if (filter[0] === '연령대별') circularType = data.author.age ==='60대' ? 'g60' : data.author.age ==='10대' ? 'g10' : data.author.age ==='20대' ? 'g20' : data.author.age ==='30대' ? 'g30' : data.author.age ==='40대' ? 'g40' : data.author.age ==='50대' ? 'g50' :'g70'
                                                            return <div key={data._id} className={`position-absolute outerCircle ${data._id === hoverId ? 'active' : ''} ${data.mine && myissue? 'mine' : ''}`} style={{top:top,left:left}} onMouseEnter={() => this.onMouseEnterHandler(data, hoverTop, hoverLeft)} onMouseLeave={() => this.onMouseLeaveHandler()} onClick={() => this.onStarClickHandler(data._id)}>
                                                                <div className={`circular ${circularType} ${data.argument.length >= 10 ? '' : 'opaque'}`}>
            
                                                                </div>
                                                            </div>
                                                        }
                                                    }
                    //                              return <div key={data._id} className={`position-absolute circular ${circularType} ${data._id === hoverId ? 'active' : ''}`} style={{top:top,left:left}} onMouseEnter={() => this.onMouseEnterHandler(data, hoverTop, hoverLeft, idx)} onMouseLeave={() => this.onMouseLeaveHandler()} onClick={() => this.onStarClickHandler(data._id)}/>
                                                })   
                                            )
                                            :
                                            (
                                                loading ? 
                                                <div className="circular-progress-wrapper">
                                                    <CircularProgress color="secondary"/>
                                                </div>
                                                :
                                                <div className="no-opinion">
                                                    의견이 아직 없습니다 :(
                                                </div>
                                            )
                                        }   
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </div>
                }
                {/* {
                    isMobile ? 
                    <Fab color="secondary" size="small" onClick={this.scrollToTop}><UpIcon /></Fab>
                    : null
                } */}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteScreen);