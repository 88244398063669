
const selectIssue = (state = '', action) => {
    switch (action.type) {
        case 'SELECT_ISSUE':
            return action.issue
        default:
            return state
    }
}

export default selectIssue