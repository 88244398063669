
const selectOption = (state = '', action) => {
    switch (action.type) {
        case 'SELECT_OPTION':
            return action.option
        default:
            return state
    }
}

export default selectOption