import React from 'react';
import Button from '../Button';

import { connect } from 'react-redux';
import { selectOption } from '../../actions';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        select: (option) => dispatch(selectOption(option))
    }
}

class Selection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: '',
        }
    }
    onClickHandler(item) {
        const { selected } = this.state;
        const { select } = this.props;
        if ( selected === '') {
            select(item);
            this.setState({
                selected: item
            })
        }
        else {
            if ( item === selected ) {
                select('');
                this.setState({
                    selected: ''
                })
            }
            else {
                select(item)
                this.setState({
                    selected: item
                })
            }
        }
    }
    render() {
        return this.props.options.map((item, i) => {
            return (<Button key={i} type="button-transparent select button" content={item} onClick={() => this.onClickHandler(item)}/>)
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Selection);