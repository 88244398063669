export const setMediaFilter = filter => ({
    type: 'SET_MEDIA_FILTER',
    filter
})

export const MediaFilters = {
    WEB: 'WEB',
    MOBILE: 'MOBILE'
}

export const selectIssue = issue => ({
    type: 'SELECT_ISSUE',
    issue
})

export const selectOption = option => ({
    type: 'SELECT_OPTION',
    option
})

export const saveProfile = profile => ({
    type: 'SAVE_PROFILE',
    profile
})

export const saveAPIToken = token => ({
    type: 'SAVE_API_TOKEN',
    token
})

export const saveOpinion = opinion => ({
    type: 'SAVE_OPINION',
    opinion
})

export const saveIssueTitle = issue => ({
    type: 'SAVE_ISSUE_TITLE',
    issue
})

export const saveIssueKey = key => ({
    type: 'SAVE_ISSUE_KEY',
    key
})