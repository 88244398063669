import { MediaFilters } from '../actions'

const setMediaFilter = (state = {'filter': MediaFilters.WEB}, action) => {
    switch (action.type) {
        case 'SET_MEDIA_FILTER':
            return action.filter
        default:
            return state
    }
}

export default setMediaFilter