
const saveIssueKey = (state = '', action) => {
    switch (action.type) {
        case 'SAVE_ISSUE_KEY':
            return action.key
        default:
            return state
    }
}

export default saveIssueKey