
const saveProfile = (state = '', action) => {
    switch (action.type) {
        case 'SAVE_PROFILE':
            return action.profile
        default:
            return state
    }
}

export default saveProfile