import React from 'react';
import { connect } from 'react-redux';
import { selectOption } from '../../actions';

const mapStateToProps = (state) => {
    const { setMediaFilter, selectIssue, selectOption } = state;
    return {
        isMobile: "MOBILE" === setMediaFilter,
        issue: selectIssue,
        option: selectOption,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        select: (option) => dispatch(selectOption(option))
    }
}

class FreeForm extends React.Component{
    onHandleChange = (event) => {
        const { select } = this.props;
        const currentValue = event.target.value;
        select(currentValue);
    };

    render() {
        const { defaultValue } = this.props
        return (
            <div className="text-center">
                <textarea className="freeform-input" placeholder="이 이슈에 대해 어떻게 생각하시는지 자유롭게 적어주세요. (최소 10자 이상)" onChange={this.onHandleChange} defaultValue={defaultValue}></textarea>
            </div>
    )}
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeForm);